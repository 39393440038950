<template>
  <div class="login">
    <TopBar title="肾小智" icon="none" backgroundColor="#fff" />
    <div class="main">
      <h2 class="title">欢迎注册登录</h2>
      <span class="tips">输入手机号与验证码后即可登录肾小智</span>
      <div class="login-main">
        <div class="phone">
          <span class="phone-text title-text">手机号码</span>
          <div class="row">
            <input class="login-input" type="text" v-model="phone" />
            <span
              style="width: 5vw"
              v-if="clickPrevent"
              class="get-check-code-disable"
              >{{ time }}</span
            >
            <span
              :class="[
                'get-check-code',
                clickPrevent ? 'get-check-code-disable' : '',
              ]"
              @click.prevent="getCheckCode"
              >{{ getCheckCodeText }}</span
            >
          </div>
        </div>
        <div class="check-code">
          <span class="code-text title-text">验证码</span>
          <input class="login-input row" type="text" v-model="checkCode" />
        </div>
        <div class="login-btn" @click="login">
          <span class="login-btn-text">登录</span>
        </div>
      </div>
    </div>
    <div class="protocol">
      <div class="statement-wrapper">
        <input
          type="radio"
          name="agreement"
          :checked="isCheck"
          @click="changeState"
        />
        <div class="content">
          已阅读并同意肾事呵护的<span class="link-font" @click="goMianZe"> 免责声明 </span>和<span
            class="link-font" @click="goYinSi">
            隐私声明
          </span>
          以及<span class="link-font" @click="goDataProtect"> 数据保护声明 </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "../../component/TopBar/index";
import { sendAliSms, login } from "../../api/login";
import { mapMutations, mapState } from "vuex";
import Cookies from "js-cookie";
import { isWechatEnv } from "../../utils";

export default {
  name: "Login",
  components: {
    TopBar,
  },
  data() {
    return {
      isSendCode: false,
      getCheckCodeText: "获取验证码",
      clickPrevent: false,
      time: 60,
      phone: "",
      checkCode: "",
      isCheck: false,
    };
  },
  methods: {
    ...mapMutations("dialog", ["SET_USER_ID"]),

    // 发送验证码
    async getCheckCode() {
      if (!this.checkPhone() || this.clickPrevent) return;
      this.time = 60;
      this.clickPrevent = true;
      let timer = setInterval(() => {
        this.time--;
        if (this.time === 0) {
          clearInterval(timer);
          this.clickPrevent = false;
        }
      }, 1000);
      const res = await sendAliSms({ phone: this.phone });
      // this.isSendCode = true;
    },

    // 点击登录
    async login() {
      const res = this.checkPhone();
      if (!res) return;
      if (!this.checkCode.trim()) {
        this.$toast("验证码不能为空");
        return;
      }
      const data = await login({
        open_id: Cookies.get("open_id") ?? "",
        phone: this.phone,
        source: isWechatEnv() ? "wechat" : "",
        code: this.checkCode,
      });

      if(!this.isCheck) {
        this.$toast("请先勾选同意");
        return;
      }

      if (data.code == 200) {
        data.data.id && this.SET_USER_ID(data.data.id);
        Cookies.set('userId', this.userId, { expires: 7, path: '' });
        this.$router.push({
          path: "/home",
        });
      } else if (data.code == 401) {
        this.$toast("验证码错误");
        return;
      }
    },

    checkPhone() {
      if (!this.phone.trim()) {
        this.$toast("手机号不能为空");
        return false;
      }

      if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        this.$toast("手机号错误");
        return false;
      }
      return true;
    },

    changeState() {
      this.isCheck = !this.isCheck;
    },

    goMianZe() {
      this.$router.push('/mianze')
    },

    goYinSi() {
      this.$router.push('/yinsi')
    },

    goDataProtect() {
      this.$router.push('/dataprotect')
    }
  },
  computed: {
    ...mapState("dialog", ["userId"])
  }
};
</script>

<style lang="less" scoped>
.login {
  position: fixed;
  top: 11.7333vw;
  background-color: #fff;
  width: 100%;
  height: 100%;

  .protocol {
    position: fixed;
    bottom: 40vw;
    width: 100%;
    height: 4vw;
    font-size: 2.9333vw;
    color: #96a1ae;

    .statement-wrapper {
      display: flex;
      align-items: center;
      padding: 0 10.1333vw;

      .link-font {
        color: #12a794;
      }

      .content {
        margin-left: 1vw;
      }
    }
  }

  .main {
    width: 100%;

    .title {
      height: 12vw;
      line-height: 12vw;
      font-size: 8vw;
      margin-left: 10.1333vw;
    }

    .tips {
      display: block;
      font-size: 2.9333vw;
      height: 4vw;
      line-height: 4vw;
      margin-left: 10.1333vw;
      color: #96a1ae;
    }

    .login-main {
      margin-left: 10.1333vw;
      padding-top: 13.3333vw;

      .login-input {
        font-size: 3.4667vw;
        margin-right: 2px;
        height: 4.8vw;
        color: #717880;
        border: none;
        outline: none;
      }

      .title-text {
        display: block;
        font-size: 4vw;
        height: 5.6vw;
        line-height: 5.6vw;
        font-weight: 700;
      }

      .row {
        width: 79.2vw;
        margin-top: 4.2667vw;
        padding-bottom: 3.4667vw;
        border-bottom: 0.1333vw solid #96a1ae;
      }

      .phone {
        .get-check-code {
          float: right;
          font-size: 3.4667vw;
          height: 4.8vw;
          line-height: 4.8vw;
          color: #12a794;
          margin-right: 4px;
        }

        .get-check-code-disable {
          float: right;
          font-size: 3.4667vw;
          color: #96a1ae;
        }
      }

      .check-code {
        margin-top: 8vw;
      }

      .login-btn {
        width: 77.6vw;
        height: 13.6vw;
        line-height: 13.6vw;
        text-align: center;
        border-radius: 6.8vw;
        background-color: #12a794;
        // background-color: #000;
        margin-top: 15.7333vw;

        .login-btn-text {
          display: inline-block;
          width: 9.8667vw;
          height: 5.3333vw;
          line-height: 5.3333vw;
          text-align-last: justify;
          color: #fff;
          font-size: 3.7333vw;
        }
      }
    }
  }
}
</style>